import React from 'react';
import styled from 'styled-components';
import breakpoints from '../utils/breakpoints';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const Hero = ({ title }) => {
  const image = useStaticQuery(graphql`
    query {
      file(name: { eq: "lineup" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 1260, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <HeroContainer>
        <Img
          fluid={image.file.childImageSharp.fluid}
          style={{ maxHeight: 'calc(50vh - 4rem' }}
          imageStyle={{ objectFit: 'contain' }}
          alt="Shield Systems Academy Students"
        />
      </HeroContainer>
    </>
  );
};

const HeroContainer = styled.div`
  position: relative;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  justify-content: right;
  @media (min-width: ${breakpoints.md}) {
    height: auto;
    min-height: 150px;
  }
`;

export default Hero;
