import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { HomeHero } from '../components/hero';
import styled from 'styled-components';

const ContactPage = () => (
  <Layout>
    <Seo
      title="Shield Systems Academy Contact Information - Martial Arts in Knoxville, Tennessee"
      description="Contact information for Shield Systems Academy. Email and directions for our adult and kids mixed martial arts, brazilian jiu jitsu and kickboxing school."
    />
    <HomeHero />
    <Wrapper>
      <h1>Contact Us</h1>
      <h2>Shield Systems Academy</h2>
      <p>
        <a href="tel:+18652147137">(865) 214 - 7137</a>
      </p>
      <p>
        We look forward to hearing from you and answering any questions you may
        have. Please feel free to reach out via email at{' '}
        <a href="mailto:shieldsystemsmma@gmail.com">
          shieldsystemsmma@gmail.com
        </a>
      </p>
    </Wrapper>
  </Layout>
);

const Wrapper = styled.div`
  margin: 2.38rem auto 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem;
`;

export default ContactPage;
